<template>
  <div>
    <WaitingForMoney />
  </div>
</template>

<script>
import WaitingForMoney from "@/components/dashboard/pages/waiting-for-money/WaitingForMoney";

export default {
  name: "LimeLoansWaitingForMoneyPage",

  components: { WaitingForMoney },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
