<template>
  <div class="waiting-for-money">
    <v-container class="pa-0 mb-0 mb-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <v-col cols="12">
              <!-- Waiting for money -->
              <h1 class="header-h1">Waiting for money</h1>

              <v-card class="transparent mt-2" width="600" flat tile>
                <p class="b-text">
                  Your money has been sent and will soon reflect on your bank
                  account. <br />
                  Please note: depending on your bank, EFT transfers could take
                  up to 48 hours to reflect in your bank account.
                </p>
              </v-card>

              <v-card class="transparent" width="600" flat tile>
                <!-- Desktop -->
                <p class="b-text mt-n2 hidden-xs-only">
                  If you have any question regarding your loan payout, please
                  contact us via
                  <a
                    class="text-decoration-none primary--text ma-0 pa-0"
                    href="mailto:support@lime24.co.za"
                    target="_blank"
                  >
                    support@lime24.co.za
                  </a>
                  and use your ID number within the message as a reference.
                </p>

                <!-- Mobile -->
                <p class="b-text mt-n2 hidden-sm-and-up">
                  In case of transfer questions, please contact us at
                  <a
                    class="text-decoration-none primary--text ma-0 pa-0"
                    href="mailto:support@lime24.co.za"
                    target="_blank"
                  >
                    support@lime24.co.za </a
                  >. Don't forget to use your ID number in the message.
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LimeLoansWaitingForMoney",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
